<template>
  <router-view/>
</template>

<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.1/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Exo&family=Exo+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

html, body{
  position: absolute;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  margin: 0;
  box-sizing: content-box;
}


#app {
  font-family: 'Exo 2', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3e3e3e;
  width: 100%;
  min-height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
