<template>
  <div class="nav">
    <hr class="myline">
    <ul>
        <li><router-link to="/about" tag="li">/about</router-link></li>
        <li><router-link to="/contact">/contact</router-link></li>
        <li><router-link to="/projects">/projects</router-link></li>
        <li><router-link to="/blog">/blog</router-link></li>
    </ul>
    
  </div>
  <div class="socials">
      <a href="https://github.com/luisdtorresp"><i class="bi bi-github"></i></a>
      <a href="https://twitter.com/luisdtorresp"><i class="bi bi-twitter"></i></a>
      <a href="https://instagram.com/luisdtorresp"><i class="bi bi-instagram"></i></a>
      <a href="https://linkedin.com/in/luisdtorresp/"><i class="bi bi-linkedin"></i></a>
      <a href="#"><i class="bi bi-telegram"></i></a>
  </div>
  <p class="copyright">
      <i>&copy; Design &amp;  Code  by</i>  <strong>Luis David Torres Perez. 2021.</strong>
  </p>
</template>

<script>
export default {
    name: 'NavFooter'
}
</script>

<style scoped>
.nav{
    margin: auto;
    padding: 1.5rem;
}
.nav ul{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    
}

.nav li {
    margin: 2% 0;
}

.nav a{
    color: #999;
    font-size: 1.6rem;
    text-decoration: none;
    padding: 1rem;
    margin:1em;
}
.socials{
    padding: 1.5rem;
}

.socials a{
    color:#3e3e3e;
    font-size: 3rem;
    margin: 1rem;
}


.myline{
    border: solid 3px #3e3e3e;
    border-radius: 4px;
    width: 80%;
    max-width: 600px;
    margin: 1.2rem auto;
}

.copyright{
    padding: 2.2rem 1.2rem;
    margin: 0;
}
</style>
