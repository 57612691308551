<template>
  <div class="bg-animation">

  <div class="main-container">
    <div class="home">
      <img class="fullname-logo" alt="Vue logo" src="@/assets/luisdtorresp-logo.svg">
      <h2>SysAdmin / Developer / Enthusiast.</h2>
      <div class="description">
        <h2 class="descriptor" > A <strong><i>Tech-person.</i></strong></h2>
      </div>
      <img tabindex="0" @click="goAbout" src="@/assets/enter-buttom.svg" alt="" id="enter">
    </div>
    <NavFooter/>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavFooter from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    NavFooter
  },
  methods: {
    goAbout: function () {
      setTimeout( () => {
        this.$router.push('about');
      }, 300)
    }
  },
  mounted() {
    window.addEventListener('keyup', event => {
      if (event.keyCode === 13) { 
        this.goAbout()
      }
    })
  },
  beforeUnmount() {
    window.removeEventListener('keyup', event => {
      if (event.keyCode === 13) { 
        this.goAbout()
      }
    })
  }
}
</script>

<style scoped>
.fullname-logo{
  margin-top: 3%;
  width: 80%;
  max-width: 540px;
  height: auto;
}

#enter{
  width: 150px;
  max-width: 80%;
  height: auto;
  padding-bottom: 1.5rem ;
  transition: all ease-in-out 0.3s;
  animation: pulsing 1.6s cubic-bezier(0.68, -0.55, 0.17, 1.55) alternate infinite;
  cursor: pointer;
  
}

#enter:focus, #enter:hover{
  transform: scale(1.1);
  filter: drop-shadow(0px 0px 6px #999 );
}

#enter:active {
  transform: scale(0.9);
  filter: drop-shadow(0px 0px 14px #333);
}

h2{
  font-size: 2.4rem;
  font-weight: 300;
  color: #3e3e3e;
}

.description{
  width: 40%;
  min-width: 260px;
  max-width: 400px;
  margin: auto;
  padding: 3em 0 4em 0;
}

h2.descriptor{
  color: #000;
  font-size: 3.2rem;
  border-top: solid 6px #3e3e3e;
  border-bottom: solid 6px #3e3e3e;
  padding: 0.2em 0 0.4em 0;
  border-radius: 6px;
  margin: 0.5rem;
}

.main-container{
  position: relative;
  z-index: 200;
  background-image: url('../assets/bg-circuits-dual.svg');
  background-position: center;
  background-size: cover;
}

.bg-animation {
    height: 100%;
    width: 100%;
    z-index: 100;
    background: linear-gradient(270deg, #e3e3e3, #fff, #222);
    background-size: 400% 400%;

    -webkit-animation: bg-animated 12s ease infinite;
    -moz-animation: bg-animated 12s ease infinite;
    animation: bg-animated 12s ease infinite;
}

@-webkit-keyframes bg-animated {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@-moz-keyframes bg-animated {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@keyframes bg-animated {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}

@keyframes pulsing {
  0%{ transform: scale(0.9)}
  0%{ transform: scale(1)}
  0%{ transform: scale(1.1)}
}

@media (max-width: 700px) {
  h2 {
    font-size: 1.5rem;
  }
  h2.descriptor{
    font-size: 2.2rem;
  }
}
</style>
